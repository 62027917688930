import {createStore as reduxCreateStore} from "redux"
import {
  STORE_ROYALTY_FLYER,
  STORE_CURRENT_REQUEST_ID,
  STORE_COUNTRY,
  STORE_NOTIFICATIONS,
  STORE_CONFIRMATION,
  STORE_MANAGER_DASHBOARD_JOBS,
  STORE_MANAGER_DASHBOARD_JOBS_MEMO,
  SET_WORK_TYPE_FILTER,
  SET_WORK_STATUS_FILTER,
  STORE_HEADOFFICE_STAFF,
  STORE_JOB_STATUSES
} from "../../constants/action-types";
const initialState = {
  current_request_id: null,
  country: null,
  notifications: [],
  confirmation: {},
  managerDashboardJobs: [],
  managerDashboardJobsMemo: [],
  workTypeFilter: 'all',
  workStatusFilter: 'all',
  headofficeStaff: [],
  jobStatuses: []
};

function reducer(state = initialState, action) {

  if (action.type === STORE_ROYALTY_FLYER) {
    return Object.assign({}, state, {royaltyFlyer: action.payload});
  }
  if (action.type === STORE_CURRENT_REQUEST_ID) {
    return Object.assign({}, state, {current_request_id: action.payload});
  }
  if (action.type === STORE_COUNTRY) {
    return Object.assign({}, state, {country: action.payload});
  }
  if (action.type === STORE_NOTIFICATIONS) {
    return Object.assign({}, state, {notifications: action.payload});
  }
  if (action.type === STORE_CONFIRMATION) {
    return Object.assign({}, state, {confirmation: action.payload});
  }
  if (action.type === STORE_MANAGER_DASHBOARD_JOBS_MEMO) {
    return Object.assign({}, state, {managerDashboardJobsMemo: action.payload});
  }
  if (action.type === STORE_MANAGER_DASHBOARD_JOBS) {
    return Object.assign({}, state, {managerDashboardJobs: action.payload});
  }
  if (action.type === SET_WORK_TYPE_FILTER) {
    return Object.assign({}, state, {workTypeFilter: action.payload});
  }
  if (action.type === SET_WORK_STATUS_FILTER) {
    return Object.assign({}, state, {workStatusFilter: action.payload});
  }
  if (action.type === STORE_HEADOFFICE_STAFF) {
    return Object.assign({}, state, {headofficeStaff: action.payload});
  }
  if (action.type === STORE_JOB_STATUSES) {
    return Object.assign({}, state, {jobStatuses: action.payload});
  }
  return state;
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
