// src/js/constants/action-types.js

export const STORE_ROYALTY_FLYER = "STORE_ROYALTY_FLYER";
export const STORE_CURRENT_REQUEST_ID = "STORE_CURRENT_REQUEST_ID";
export const STORE_COUNTRY = "STORE_COUNTRY";
export const STORE_NOTIFICATIONS = "STORE_NOTIFICATIONS";
export const STORE_CONFIRMATION = "STORE_CONFIRMATION";
export const STORE_MANAGER_DASHBOARD_JOBS = "STORE_MANAGER_DASHBOARD_JOBS";
export const STORE_HEADOFFICE_STAFF = "STORE_HEADOFFICE_STAFF";
export const STORE_MANAGER_DASHBOARD_JOBS_MEMO = "STORE_MANAGER_DASHBOARD_JOBS_MEMO";
export const SET_WORK_TYPE_FILTER = "SET_WORK_TYPE_FILTER";
export const SET_WORK_STATUS_FILTER = "SET_WORK_STATUS_FILTER";
export const STORE_JOB_STATUSES = "STORE_JOB_STATUSES";
