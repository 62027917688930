import React from "react"
import { Provider } from "react-redux"
import createStore from "./src/redux/store";
import UserStore from './src/context/UserWrapper';
import './src/content/icons/flaticon.css';
import './src/content/css/tailwind/tailwind.css';
import './src/content/css/app.css';
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  return <Provider store={store}><UserStore>{element}</UserStore></Provider>
}